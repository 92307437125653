import { useState, useEffect } from "react"
import { collection, query, where, getDocs, orderBy } from "firebase/firestore"
import { db } from "../firebase/config"

const usePage = name => {
  const [data, setData] = useState([])

  useEffect(() => {
    const fetchPage = async () => {
      const q = query(collection(db, "pages"), where("name", "==", name))
      const querySnapshot = await getDocs(q)
      let arr = []
      querySnapshot.forEach(doc => {
        arr.push(doc.data().widgets)
        console.log("data", doc.data().widgets)
      })
      setData(arr)
      console.log("dataend", data)
    }

    fetchPage()
  }, [name])

  return [data]
}

export default usePage
